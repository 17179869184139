import React from "react";
import * as FlexmonsterReact from "react-flexmonster";
import "flexmonster/flexmonster.css";
import axios from "axios";

function Ainqaflexmon(props) {
  const pivot = React.useRef(null);
  const [dataload, setDataload] = React.useState(false);
  const [loaded, setLoaded] = React.useState([]);
  const [foottrim, setFoottrim] = React.useState("");
  const [reporttype, setReporttype] = React.useState("");
  const [jrxmlread, setJrxmlread] = React.useState("");
  const onReportComplete = () => {
    console.log(">>>>", pivot.current.flexmonster.getReport());
  };
  // function flexmonsterSetReport() {
  //   let report = location.state;
  //   pivot.current.flexmonster.setReport(report);
  // }
  React.useEffect(() => {
    if (props.reportid.length !== 0) {
      setDataload(true);
    }
    var temp1 = {
      db_name: process.env.REACT_APP_DB_NAME,
      entity: process.env.REACT_APP_ENTITY,
      filter: `${process.env.REACT_APP_ENTITY}.reportid=='${props.reportid}'`,
      return_fields: process.env.REACT_APP_ENTITY,
    };
    var config1 = {
      method: "post",
      url: process.env.REACT_APP_API_URL,
      headers: { "Content-Type": "application/json" },
      data: temp1,
    };

    axios(config1)
      .then((res) => {
        console.log(res.data.result[0]);
        setReporttype(res.data.result[0].reporttype);
        setJrxmlread(res.data.result[0].jrxmltemplate);

        if (res.data.result[0].reporttype === "flexmonster") {
          res.data.result[0] && setLoaded(res.data.result[0]);
          var footertrim = [];
          if (Object.keys(res.data.result[0]?.fmheadfoot).length !== 0) {
            footertrim = res.data.result[0].fmheadfoot.footer.split("<d");
            setFoottrim(footertrim);
          }
          var norfilter;
          var filters = JSON.parse(res.data.result[0].readquery);
          console.log("filters", filters?.filter);
          if (props.reportid.length !== 0) {
            if (filters?.filter !== undefined) {
              var filtertest = JSON.parse(res.data.result[0].readquery);
              var splitq = filtertest.filter;
              Object.keys(filtertest.filter).map((e) => {
                //getting filter key values
                // var newsplits=query.split("@").join(" ").split(" ");
                return (norfilter = splitq[e].indexOf("@"));
              });
            }
            console.log("ainqaflex-read query", res.data.result[0].readquery);
            if (filtertest?.filter !== undefined && norfilter !== -1) {
              var jsonparse = JSON.parse(res.data.result[0].readquery);
              delete jsonparse.filter;
              // console.log("entered split");
              // var jsonparse = JSON.parse(res.data.result[0].readquery);
              // var splitquery = jsonparse.filter.split("@");
              // var newsplit = splitquery.join(" ");
              // var finalsplit = newsplit.split(" ");
              // finalsplit.map((e, index) => {
              //   return (
              //     props.inputparams[e] !== undefined &&
              //     (finalsplit[index] = props.inputparams[e])
              //   );
              // });
              // var finalreadquery = finalsplit.join(" ");
              // console.log("show length", finalreadquery);
              // var filteradd = {
              //   filter: finalreadquery,
              // };
              // Object.assign(jsonparse, filteradd);
              // console.log("jsonparse read", jsonparse);
            } else {
              jsonparse = JSON.parse(res.data.result[0].readquery);
            }
            console.log("json", jsonparse);
            var config = {
              method: "post",
              url: process.env.REACT_APP_API_URL,
              headers: { "Content-Type": "application/json" },
              data: jsonparse,
            };

            delete res.data.result[0]?.template.dataSource;

            axios(config)
              .then((response) => {
                var data = [];
                var newdata = [];
                var nomap = [];
                var template = res.data.result[0].template;
                var dataSource = {
                  dataSource: { data: response.data.result, type: "json" },
                };
                if (res.data.result[0].fmrender !== 0)
                  res.data.result[0].fmrender.map((e) => {
                    data = e.columnvalue.split("/{");
                    // console.log("data", data);
                    data.map((e) => {
                      var len = e.length;
                      return e[len - 1] === "}"
                        ? newdata.push(e.replace(/}/g, ""))
                        : nomap.push(e);
                    });

                    data = [];
                    newdata.map((e) => data.push(e.replace(/{/g, "")));
                    // console.log("final data", newdata);
                    var columnName = e.columnName;
                    var columnType = "string";
                    var currentData = dataSource.dataSource.data;
                    currentData[0][columnName] = { type: columnType };
                    return currentData.map((v) => {
                      // console.log(data.length, "length of data");
                      var sampltemp = [];
                      var valuemap = [];
                      valuemap = data ? data : newdata;
                      return valuemap.map((g) => {
                        // console.log("data as e", v[e]);
                        var teemp = v[g];
                        sampltemp.push(teemp);
                        // console.log("concat", sampltemp.join());
                        // console.log("nomap value", nomap.join("/"));
                        var display;
                        if (nomap.length === 1) {
                          display = nomap + "/" + sampltemp.join("/");
                        } else {
                          display = nomap.join("/") + sampltemp.join("/");
                        }
                        var store = {
                          [columnName]: display,
                        };
                        return Object.assign(v, store);
                        // console.log("urlssss", v);
                      });
                    });
                  });
                // console.log("render-values", render);
                var result = {};
                result = Object.assign(template, dataSource);
                // console.log("final result", result, result);
                setDataload(false);
                pivot.current.flexmonster.setReport(result);
              })
              .catch((err) => {
                console.error(err);
              });
          }
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [props]);

  /////function for flexmonster toolbar icons/////

  function customizeToolbar(toolbar) {
    let tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      tabs = tabs.filter(
        (tab) =>
          tab.id !== "fm-tab-connect" &&
          tab.id !== "fm-tab-save" &&
          tab.id !== "fm-tab-open"
      );
      // console.log("data is loaded", loaded);
      // menu: [
      //   {
      //     id: "fm-tab-add-column",
      //     title: "Add Column",
      //     icon: "<img src='https://img.icons8.com/small/30/000000/add.png' style='place-self:center;margin:3.5px 0' alt='Add' />",
      //   },
      // ],

      let exportTab = tabs.find((tab) => tab.id === "fm-tab-export");
      if (!exportTab) return tabs;

      let exportToPdfTab = exportTab.menu.find(
        (tab) => tab.id === "fm-tab-export-pdf"
      );
      console.log("typescript test", loaded.fmheadfoot);
      if (loaded.fmheadfoot)
        exportToPdfTab.handler = () => {
          // setDiaforpdf(true);
          pivot.current.flexmonster.exportTo("pdf", {
            header: loaded.fmheadfoot.header,
            footer: loaded.fmheadfoot.footer,
            pageOrientation: loaded.fmheadfoot.pageOrientation,
          });
        };
      return tabs;
    };
  }
  // const customizeCellFunction = () => {
  //   console.log("called");
  //   pivot.current.flexmonster.customizeCell((cell, data) => {
  //     if (
  //       !(
  //         (data.hierarchy && data.hierarchy.uniqueName === "url") ||
  //         data.hierarchy.uniqueName === "image" ||
  //         data.hierarchy.uniqueName === "activestatus" ||
  //         data.hierarchy.uniqueName === "updatedby"
  //       )
  //     )
  //       return;
  //     else if (data.hierarchy && data.hierarchy.uniqueName === "url") {
  //       let newText = `<a href=${data.label} class="link" target='_blank'>${data.escapedLabel}</a>`;
  //       if (data.type === "value") {
  //         cell.style["z-index"] = 1;
  //         cell.text = newText;
  //       }
  //     } else if (
  //       data.hierarchy &&
  //       data.hierarchy.uniqueName === "activestatus"
  //     ) {
  //       if (data.label === "false") {
  //         //event function definition is declared in index.html file
  //         let newText = `${data.label} <button onclick="buttonclick(event)" class="buttonstyle" value=${data.label} >change</button>`;
  //         // `<button onchange="buttonclick(event)">change</button>`;
  //         if (data.type === "value") {
  //           cell.style["z-index"] = 3;
  //           cell.text = newText;
  //         }
  //       }
  //     } else if (data.hierarchy && data.hierarchy.uniqueName === "updatedby") {
  //       let newText = `<input type="checkbox" name="checkbox" onchange="onCheck(event)">Value</input>`;
  //       if (data.type === "value") {
  //         cell.style["z-index"] = 1;
  //         cell.text = newText;
  //       }
  //     } else {
  //       //event function definition is declared in index.html file
  //       let newText = `<a href=${data.label} class="image" target="_blank"}><img src=${data.label} width="60px" height="50px" alt="this is image"></img></a>
  //       <input type="checkbox" name="checkbox" onchange="onCheck(event)">Value</input>`;
  //       if (data.type === "value") {
  //         cell.style["z-index"] = 1;
  //         cell.text = newText;
  //       }
  //     }
  //   });
  // };
  // function save() {
  //   var temp = {};
  //   temp = pivot.current.flexmonster.getReport({
  //     withGlobals: true,
  //   });
  //   delete temp.dataSource;
  //   var dataSource = { dataSource: { data: [], type: "json" } };
  //   var result = {};
  //   result = Object.assign(temp, dataSource);
  //   console.log("function called", result);
  //   var datas = [
  //     {
  //       db_name: "Vectras01",
  //       entity: "reports",
  //       is_metadata: false,
  //       doc: {
  //         result,
  //       },
  //     },
  //   ];
  //   var config = {
  //     method: "post",
  //     url: "http://164.52.210.54:8080/api/upsert_document",
  //     header: { "Content-Type": "application/json" },
  //     data: datas,
  //   };
  //   axios(config)
  //     .then((res) => {
  //       console.log(res.status);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }
  // const update = () => {
  //   console.log("called update", jsondata[0]);
  //   pivot.current.flexmonster.updateData({ data: jsondata });
  // };
  // console.log("dataload", foottrim);
  return (
    <>
      {/* <button
        onClick={() => {
          save();
        }}
      >
        SAVE TEMPLATE
      </button>
      <button
        onClick={() => {
          customizeCellFunction();
        }}
      >
        CELL CUSTOMIZATION
      </button>
      <button
        onClick={() => {
          pivot.current.flexmonster.customizeCell(null);
        }}
      >
        CLEAR CUSTOMIZATION
      </button>
      <button className="button-red" onClick={() => update()}>
        UPDATE
      </button> */}
      {reporttype === "jasper" ? (
        <div>
          <textarea value={jrxmlread} rows="35" cols="165" disabled></textarea>
        </div>
      ) : (
        <div>
          {dataload && (
            <p style={{ display: "flex", justifyContent: "center" }}>
              Please wait while the data is loading
            </p>
          )}
          {!dataload && props.reportid.length !== 0 && (
            <>
              {console.log("loaded", loaded)}
              {loaded.length !== 0 &&
                Object.keys(loaded.fmheadfoot).length !== 0 && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${loaded.fmheadfoot.header}`,
                    }}
                  ></div>
                )}
              <FlexmonsterReact.Pivot
                ref={pivot}
                toolbar={true}
                width="100%"
                height={500}
                // ready={()=>onReady()}
                // report={{
                //   dataSource: {
                //     data: location.state,
                //   },
                // }}
                // customizeCell={() => customizeCellFunction()}
                // ready={() => flexmonsterSetReport()}
                reportcomplete={onReportComplete}
                licenseKey="Z7SQ-XDC84O-5H7144-0W4O65"
                // beforetoolbarcreated={customizeToolbar}
              />
              {loaded.length !== 0 &&
                Object.keys(loaded?.fmheadfoot).length !== 0 && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${foottrim[0]}`,
                    }}
                  ></div>
                )}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Ainqaflexmon;
