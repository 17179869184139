import React from "react";
import { SvgIcon } from "@mui/material";
export const Databaseicon = () => {
  return (
    <SvgIcon
      //   xmlns="http://www.w3.org/2000/SvgIcon"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      //   version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={{ fontSize: "1.2rem" }}
      //   style={{enable-background:"new 0 0 512 512"}}
      //   xml:space="preserve"
    >
      <g>
        <g>
          <path d="M403.995,27.498C363.93,9.765,311.372,0,256.002,0c-55.375,0-107.931,9.756-147.986,27.469    c-44.53,19.692-69.053,47.47-69.053,78.217c0,0.242,0.014,0.482,0.018,0.723c-0.004,0.149-0.022,0.294-0.022,0.443l0.004,304.974    c0,29.476,24.488,55.911,68.954,74.439C147.746,502.861,200.338,512,256.007,512c55.669,0,108.26-9.139,148.089-25.735    c44.465-18.528,68.843-44.964,68.843-74.439V105.739c0-0.058,0.104-0.112,0.102-0.17C472.951,74.89,448.441,47.169,403.995,27.498    z M121.522,58.007c35.895-15.874,83.654-24.616,134.48-24.616c50.817,0,98.576,8.751,134.478,24.641    c30.791,13.628,49.174,31.462,49.174,47.707s-18.383,34.079-49.174,47.707c-35.902,15.89-83.661,24.641-134.478,24.641    c-105.11,0-183.648-38.224-183.648-72.401C72.354,89.445,90.735,71.622,121.522,58.007z M439.659,411.826    c0,14.682-18.096,30.987-48.406,43.617c-35.853,14.938-83.885,23.166-135.246,23.166s-99.393-8.228-135.246-23.166    c-30.31-12.63-48.518-28.935-48.518-43.617v-45.178c9.831,7.149,21.84,13.68,35.675,19.445    c39.828,16.594,92.419,25.734,148.088,25.734c55.669,0,108.26-9.139,148.089-25.735c13.834-5.764,25.732-12.295,35.563-19.444    V411.826z M391.253,355.269c-35.853,14.938-83.885,23.166-135.246,23.166s-99.393-8.228-135.246-23.166    c-30.31-12.63-48.406-28.935-48.406-43.617c0-0.01-0.112-0.019-0.112-0.028l-0.001-45.152c9.832,7.149,21.841,13.68,35.676,19.446    c39.829,16.594,92.42,25.734,148.089,25.734c55.669,0,108.26-9.139,148.089-25.735c13.834-5.764,25.732-12.295,35.563-19.444    v45.178h0C439.659,326.334,421.563,342.639,391.253,355.269z M391.253,255.095c-35.853,14.938-83.885,23.166-135.246,23.166    s-99.393-8.228-135.246-23.166c-30.31-12.63-48.522-28.935-48.522-43.617c0-0.019,0.001-0.038,0.001-0.057l-0.001-48.105    c9.853,7.559,21.895,14.488,35.769,20.635c40.072,17.751,92.632,27.527,147.995,27.527c55.369,0,107.927-9.765,147.992-27.498    c13.877-6.142,25.809-13.069,35.664-20.626v48.124h0C439.659,226.16,421.563,242.465,391.253,255.095z" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </SvgIcon>
  );
};
