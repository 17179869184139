import styled from "@emotion/styled";
import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@material-ui/core";

import React from "react";

const UseSytle = makeStyles((theme) => ({
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,

    "& li": {
      // fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "1rem",
      fontFamily: "TDS_Regular",
    },
    "& li:hover": {
      color: "#fff",
      background: theme.palette.info.main,
    },
    "& li.Mui-selected": {
      color: "#000",
      background: theme.palette.info.light,
    },
    "& li.Mui-selected:hover": {
      // background: "#6EC177",
      color: "#fff",
      background: theme.palette.info.main,
    },
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #e0e0e0",
    fontSize: "1rem",
    fontFamily: "TDS_Regular",
    padding: "10px 26px 10px 12px",
    // minWidth: "60px",
    // transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    // "&:focus": {
    //   borderRadius: 4,
    //   // borderColor: "#80bdff",
    //   // boxShadow: "0 0 0 0.5rem rgba(0,123,255,.25)",
    // },
    // "& .MuiNativeSelect-select": {
    //   padding: "24px",
    // },
    // "& :not([multiple]) option": {
    //   height: "40px",
    // },
  },
}));
const multipleEntity = (props) => {
  const classes = UseSytle();
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };
  const { data } = props;
  return (
    <Box style={{ height: "calc(100% - 180px)", overflow: "scroll" }}>
      <Grid style={{ margin: "10px 100px" }}>
        {/* <Grid item lg={12}> */}
        {data?.map((list, i) => (
          <Grid item lg={12} key={i}>
            <Typography>{list?.entity}</Typography>
            {Object.keys(list?.object).map((l, index) => (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid
                  key={index}
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="GrayText"
                    style={{ textTransform: "capitalize" }}
                  >
                    {`${l}`}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "8px 0",
                      // justifyContent: "space-between",
                    }}
                  >
                    <FormControl style={{ width: 250 }} size="small">
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        //   value={sampleMetaJson[`${l}`]}
                        //   onChange={(e) => handleSchema(`${l}`, e.target.value)}
                        fullWidth
                        input={<BootstrapInput />}
                        MenuProps={menuProps}
                      >
                        {menu?.map((v, index) => {
                          return (
                            <MenuItem key={index} value={v.value}>
                              {v.type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "12px",
                      }}
                    >
                      {/* <Typography
                              variant="body2"
                              color="GrayText"
                              style={{ textTransform: "capitalize" }}
                            >
                              {"Is Required"}
                            </Typography> */}
                      <FormControlLabel
                        control={
                          <Switch
                            //   checked={isRequiredJson[`${l}`]}
                            //   onChange={(e) =>
                            //     handleIsRequired(`${l}`, e.target.checked)
                            //   }
                            name="checkedB"
                            color="primary"
                          />
                        }
                        // label={"Is Required"}
                      />
                      <Typography
                        variant="body2"
                        color="GrayText"
                        style={{ textTransform: "capitalize" }}
                      >
                        {"Is Required"}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </div>
            ))}
            <Divider />
          </Grid>
        ))}
        {/* </Grid> */}
      </Grid>
    </Box>
  );
};

export default multipleEntity;

export const menu = [
  { type: "String", value: "string" },
  { type: "Number", value: "number" },
  { type: "Boolean", value: "boolean" },
  { type: "Array", value: "array" },
];
