import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Dialog, IconButton, Paper, Typography } from "@material-ui/core";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Delete from "./Dailogsandpopups/Delete";
import { Button, Container } from "@material-ui/core";
import LoadingSpinner from "./LoadingSpinner";
function Homepage() {
  const history = useNavigate();
  const [dataload, setDataload] = React.useState([]);
  const [deleteid, setDeleteid] = React.useState("");
  const [deletedialog, setDeletedialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const reportPage = () => {
    history("/steps");
  };

  var update = localStorage.getItem("reload");
  React.useEffect(() => {
    // if (update === "true" || update === "" || update === "false") {
    // window.location.reload(true);
    // console.log("called render");
    setIsLoading(true);
    var temp1 = {
      db_name: process.env.REACT_APP_DB_NAME,
      entity: process.env.REACT_APP_ENTITY,
      filter: `${
        process.env.REACT_APP_ENTITY
      }.metadataid=='${sessionStorage.getItem("metadataId")}' AND ${
        process.env.REACT_APP_ENTITY
      }.activestatus==true `,
      // ismime_read: "true",
      return_fields: process.env.REACT_APP_ENTITY,
    };
    var config1 = {
      method: "post",
      url: process.env.REACT_APP_API_URL,
      headers: { "Content-Type": "application/json" },
      data: temp1,
    };
    axios(config1)
      .then((res) => {
        console.log("homepage", res.data.result);
        setDataload(res.data.result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
    localStorage.setItem("reload", "false");
    // }
  }, [update]);

  /////Delete dailog open function////
  const handledelete = (v) => {
    setDeletedialog(true);
    console.log("v._key", v._key);
    setDeleteid(v._key);
    // console.log(v);
  };
  /////delete function///
  const deletefunc = () => {
    var temp = {
      db_name: process.env.REACT_APP_DB_NAME,
      entity: process.env.REACT_APP_ENTITY,
      filter: `${process.env.REACT_APP_ENTITY}._key=='${deleteid}'`,
    };
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL_DELETE,
      headers: { "Content-Type": "application/json" },
      data: temp,
    };
    axios(config)
      .then((res) => {
        // console.log(res.data);
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err.data);
      });
    setDeletedialog(false);
  };

  /////deletepopupclose function/////
  const handleClosefordeletepopup = () => {
    setDeletedialog(false);
  };

  ///download function

  return (
    <>
      <div
        style={{
          // display: "flex",
          // flexDirection: "column",
          padding: "20px",
          height: "100%",
        }}
      >
        {isLoading ? <LoadingSpinner /> : null}
        {/* <Container> */}
        {/* <Typography
            style={{
              fontWeight: "700",
              fontFamily: "Arial",
              margin: "3px",
              padding: "2px",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            QDM Reports
          </Typography> */}

        <Paper
          style={{
            boxShadow: "3px 3px 10px 2px rgba(0, 0, 0, 0.25)",
            padding: "10px",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "44px",
              padding: "0px 8px",
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontWeight: "600",
                // margin: "3px",
                // padding: "8px",
              }}
            >
              Report Configuration View
            </Typography>

            <Button
              onClick={() => {
                reportPage();
              }}
              color="primary"
              variant="contained"
            >
              Add+
            </Button>
          </div>
          <TableContainer
            style={{
              borderRadius: "15px",
              border: "1.5px solid grey ",
              marginTop: "8px",
              height: "calc(100% - 54px)",
            }}
            // sx={{ maxHeight: 450 }}
          >
            <Table stickyHeader={true} size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "white",
                      backgroundColor: "#00205C",
                      borderBottom: "0px",
                      height: "33px",
                    }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      backgroundColor: "#00205C",
                      borderBottom: "0px",
                      height: "33px",
                    }}
                  >
                    Report Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      backgroundColor: "#00205C",
                      borderBottom: "0px",
                      height: "33px",
                    }}
                  >
                    Report Type
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      backgroundColor: "#00205C",
                      borderBottom: "0px",
                      height: "33px",
                    }}
                  >
                    View
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      backgroundColor: "#00205C",
                      borderBottom: "0px",
                      height: "33px",
                    }}
                  >
                    Edit
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      backgroundColor: "#00205C",
                      borderBottom: "0px",
                      height: "33px",
                    }}
                  >
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataload?.map((v, index) => {
                  return (
                    <TableRow key={v.reportid}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{v.reportname}</TableCell>
                      <TableCell>{v.reporttype}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => history(`/view/${v.reportid}`)}
                        >
                          <VisibilitySharpIcon
                            fontSize="small"
                            color="disabled"
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => history("/edit", { state: v })}
                        >
                          <EditIcon fontSize="small" color="info" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            handledelete(v);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <DeleteIcon fontSize="small" color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Dialog
          open={deletedialog}
          onClose={() => {
            setDeletedialog(false);
          }}
        >
          <Delete
            handleClosefordeletepopup={handleClosefordeletepopup}
            deletefunc={deletefunc}
          />
        </Dialog>
        {/* </Container> */}
      </div>
    </>
  );
}

export default Homepage;
