import axios from "axios";
import { v4 as uuidv4 } from "uuid";
// import { v4 as uuid } from "uuid";
export const AxiosCall = async (url, params) => {
  let config = {
    method: "post",
    url: `${url}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  };
  let data = await axios(config);
  return data;
};

export const getReport = async (dbName, reportid, readUrl) => {
  try {
    let params = {
      db_name: `${dbName}`,
      entity: "QDMReports",
      filter: `QDMReports.reportid=='${reportid}' && QDMReports.activestatus==true`,
      return_fields: "QDMReports",
    };
    const res = await AxiosCall(readUrl, params);
    const data = await res.data;
    return data;
  } catch (error) {
    let data = { Code: 1111, error: true };
    return data;
  }
};

export const upsertReport = async (
  url,
  existingreport,
  reportname,
  access,
  template,
  dbname
) => {
  let params = [
    {
      db_name: dbname,
      entity: "QDMReports",
      is_metadata: false,
      doc: {
        template: template,
        access: access,
        reportid: uuidv4(),
        reportname: reportname,
        readquery: existingreport.readquery,
        readjsonschema: existingreport.readjsonschema,
        reporttype: existingreport.reporttype,
        querySchema: existingreport.querySchema,
        filterObj: existingreport.filterObj,
        fmrender: existingreport.fmrender,
        fmheadfoot: existingreport.fmheadfoot,
        clientid: existingreport.clientid,
        metadataid: existingreport.metadataid,
        projectid: existingreport.projectid,
      },
    },
  ];
  const res = await AxiosCall(url, params);
  const data = await res.data;
  return data;
};

export const upsertRepo = async (
  url,
  dbName,
  repname,
  parent_rep,
  _id,
  clientid,
  metadataid,
  projectid
) => {
  try {
    let params = [
      {
        db_name: dbName,
        entity: "IDM_Repository",
        is_metadata: false,
        doc: {
          rep_id: uuidv4(),
          rep_type: "Report Configurator",
          rep_name: repname,
          parent_rep: parent_rep ?? "",
          route_url: "",
          is_active: true,
          unique_id: _id,
          projectid: projectid,
          clientid: clientid,
          metadataid: metadataid,
        },
      },
    ];
    let res = await AxiosCall(url, params);
    const data = await res.data.Result[0]?.properties?.doc;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllIdmPermission = async (dbname) => {
  try {
    let params = {
      db_name: dbname,
      entity: "IDM_PermissionManagement",
    };
    const res = await AxiosCall(
      "https://idmservices.dev.ainqaplatform.in/getAllPermissions",
      params
    );
    const data = await res.data;
    return data;
  } catch (error) {
    let data = { Code: 1111, error: true };
    return data;
  }
};

export const getPrivatePermission = async (dbname, url, userid) => {
  // debugger;
  try {
    let params = {
      db_name: dbname,
      filter: { emailId: userid },
      queryid: "d48156fc-a718-4eea-99d0-9b7209d5cba6",
    };
    const res = await AxiosCall(url, params);
    const data = await res?.data[0]?.perrolepermsnid;
    return data;
  } catch (error) {
    let data = { Code: 1111, error: true };
    return data;
  }
};

export const updatePermissionList = async (dbname, url, doc, _key) => {
  let params = [
    {
      db_name: "dbname",
      entity: "IDM_PermissionManagement",
      is_metadata: true,
      metadataId: "04ecb73d-f048-44ce-8563-c9be015812dd",
      filter: { _key: _key },
      doc: doc,
    },
  ];
  try {
    let res = await AxiosCall(url, params);
    const data = await res.data.Result[0]?.properties?.doc;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const upsertPermissionList = async (dbname, url, doc, _key) => {
  let params = [
    {
      db_name: "dbname",
      entity: "IDM_PermissionManagement",
      is_metadata: true,
      metadataId: "04ecb73d-f048-44ce-8563-c9be015812dd",
      filter: { _key: _key },
      doc: doc,
    },
  ];
  try {
    let res = await AxiosCall(url, params);
    const data = await res.data.Result[0]?.properties?.doc;
    return data;
  } catch (error) {
    console.log(error);
  }
};
