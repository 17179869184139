import React from "react";
import { SvgIcon } from "@mui/material";
export const ProjectName = () => {
  return (
    <SvgIcon
      //   xmlns="http://www.w3.org/2000/SvgIcon"
      width="128"
      height="128"
      viewBox="0 0 128 128"
    >
      <path d="M114,15.25H14A9.761,9.761,0,0,0,4.25,25v77A10.762,10.762,0,0,0,15,112.75h98A10.762,10.762,0,0,0,123.75,102V25A9.761,9.761,0,0,0,114,15.25ZM120.25,25V36.683H52.888L61.37,18.75H114A6.257,6.257,0,0,1,120.25,25ZM14,18.75H57.5L49.016,36.683H7.75V25A6.257,6.257,0,0,1,14,18.75Zm99,90.5H15A7.258,7.258,0,0,1,7.75,102V40.183h112.5V102A7.258,7.258,0,0,1,113,109.25Z" />
      <path d="M21.57,33.466a5.75,5.75,0,1,0-5.75-5.75A5.756,5.756,0,0,0,21.57,33.466Zm0-8a2.25,2.25,0,1,1-2.25,2.25A2.253,2.253,0,0,1,21.57,25.466Z" />
      <path d="M37.626,33.466a5.75,5.75,0,1,0-5.75-5.75A5.756,5.756,0,0,0,37.626,33.466Zm0-8a2.25,2.25,0,1,1-2.25,2.25A2.253,2.253,0,0,1,37.626,25.466Z" />
      <path d="M67.522,29.466h44.745a1.75,1.75,0,0,0,0-3.5H67.522a1.75,1.75,0,0,0,0,3.5Z" />
      <path d="M65.479,86.14l-3.561-2.755a22.581,22.581,0,0,0,0-17.819l3.561-2.754a1.748,1.748,0,0,0,.314-2.456l-4.9-6.328a1.75,1.75,0,0,0-2.454-.313l-3.567,2.759a22.723,22.723,0,0,0-8.127-4V47.976A1.749,1.749,0,0,0,45,46.226H37a1.749,1.749,0,0,0-1.75,1.75v4.495a22.731,22.731,0,0,0-8.127,4l-3.567-2.759a1.749,1.749,0,0,0-2.454.313l-4.895,6.328a1.748,1.748,0,0,0,.314,2.456l3.561,2.754a22.581,22.581,0,0,0,0,17.819L16.521,86.14a1.749,1.749,0,0,0-.314,2.455L21.1,94.923a1.749,1.749,0,0,0,2.454.314l3.567-2.759a22.713,22.713,0,0,0,8.127,4v4.5a1.75,1.75,0,0,0,1.75,1.75h8a1.75,1.75,0,0,0,1.75-1.75v-4.5a22.7,22.7,0,0,0,8.127-4l3.567,2.759a1.75,1.75,0,0,0,2.454-.314l4.9-6.328A1.749,1.749,0,0,0,65.479,86.14ZM59.2,91.4l-3.288-2.542a1.748,1.748,0,0,0-2.225.07,19.209,19.209,0,0,1-9.02,4.441,1.75,1.75,0,0,0-1.418,1.718v4.142h-4.5V95.084a1.75,1.75,0,0,0-1.418-1.718,19.2,19.2,0,0,1-9.019-4.441,1.749,1.749,0,0,0-2.226-.07L22.8,91.4l-2.753-3.559,3.283-2.54a1.75,1.75,0,0,0,.492-2.173,19.1,19.1,0,0,1,0-17.3,1.75,1.75,0,0,0-.492-2.173l-3.283-2.539,2.753-3.56L26.087,60.1a1.75,1.75,0,0,0,2.226-.07,19.2,19.2,0,0,1,9.019-4.442,1.75,1.75,0,0,0,1.418-1.718V49.726h4.5v4.141a1.75,1.75,0,0,0,1.418,1.718,19.211,19.211,0,0,1,9.02,4.442,1.749,1.749,0,0,0,2.225.07L59.2,57.554l2.753,3.56-3.283,2.539a1.75,1.75,0,0,0-.492,2.173,19.1,19.1,0,0,1,0,17.3,1.75,1.75,0,0,0,.492,2.173l3.283,2.54Z" />
      <path d="M41,60.739a13.75,13.75,0,1,0,13.75,13.75A13.766,13.766,0,0,0,41,60.739Zm0,24a10.25,10.25,0,1,1,10.25-10.25A10.261,10.261,0,0,1,41,84.739Z" />
      <path d="M111.409,55.489h-33a1.75,1.75,0,0,0,0,3.5h33a1.75,1.75,0,0,0,0-3.5Z" />
      <path d="M111.409,66.989h-33a1.75,1.75,0,0,0,0,3.5h33a1.75,1.75,0,1,0,0-3.5Z" />
      <path d="M111.409,78.489h-33a1.75,1.75,0,0,0,0,3.5h33a1.75,1.75,0,1,0,0-3.5Z" />
      <path d="M111.409,89.989h-33a1.75,1.75,0,0,0,0,3.5h33a1.75,1.75,0,1,0,0-3.5Z" />
    </SvgIcon>
  );
};
