import React from "react";
import packageJson from "../../../package.json";
// import Button from '@mui/material/Button';
import { Avatar, makeStyles, Box } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ProjectName } from "../../assets/development";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import { Databaseicon } from "../../assets";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';

// import Popover from "@material-ui/core/Popover";
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import { DrawerContext } from "../../../contexts";
// import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  title: {
    display: "block",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userProfilecard: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
  },
  profilePic: {
    marginLeft: "8px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,

    "& li": {
      // fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "1rem",
      fontFamily: "TDS_Regular",
    },
    "& li:hover": {
      color: theme.palette.common.white,
      background: theme.palette.info.main,
    },
    "& li.Mui-selected": {
      color: theme.palette.common.black,
      background: theme.palette.info.light,
    },
    "& li.Mui-selected:hover": {
      // background: "#6EC177",
      color: theme.palette.common.white,
      background: theme.palette.info.main,
    },
  },
}));

export const NavBar = (props) => {
  const classes = useStyles();
  const { projectName, clientName } = props;

  //   const [anchorEl, setAnchorEl] = React.useState(null);

  /*   React.useEffect(() => {
    setAge(props?.defaultScenarios);
  }, [props?.defaultScenarios]); */

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  //   const handleLogout = () => {
  //     localStorage.removeItem("authToken");
  //   };

  //   const open = Boolean(anchorEl);
  //   const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar style={{ padding: "0 16px" }}>
          <div className={classes.titleContainer}>
            {/* <Typography className={classes.title} variant="body1" noWrap>
              {"Report Builder"}
            </Typography> */}
            {clientName && projectName && (
              <Box style={{ display: "flex", alignItems: "center" }}>
                <>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <PersonOutlineOutlinedIcon style={{ fontSize: "1.3em" }} />
                    <Typography
                      style={{ marginLeft: "5px" }}
                      variant="body1"
                      noWrap
                    >
                      {clientName}
                    </Typography>
                  </Box>

                  <Divider
                    orientation="vertical"
                    style={{
                      height: "20px",
                      margin: "0 8px",
                      // borderColor: "#ffffff",
                      background: "#fff",
                    }}
                  />
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <ProjectName />
                    {/* <SourceOutlinedIcon style={{ fontSize: "1.3em" }} /> */}
                    <Typography
                      style={{ marginLeft: "6px" }}
                      variant="body1"
                      noWrap
                    >
                      {projectName}
                    </Typography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    style={{
                      height: "20px",
                      margin: "0 8px",
                      // borderColor: "#ffffff",
                      background: "#fff",
                    }}
                  />
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Databaseicon />
                    <Typography
                      style={{ marginLeft: "6px" }}
                      variant="body1"
                      noWrap
                    >
                      {sessionStorage.getItem("databasename")}
                    </Typography>
                  </Box>
                </>
              </Box>
            )}
          </div>

          <div className={classes.grow} />

          <div className={classes.userProfilecard}>
            <Typography variant="body2">{`v ${packageJson.version}`}</Typography>
            <Divider
              orientation="vertical"
              style={{
                height: "20px",
                margin: "0 8px",
                // borderColor: "#ffffff",
                background: "#fff",
              }}
            />
            <Typography className={classes.title} variant="body1" noWrap>
              Admin
            </Typography>
            <Avatar className={classes.profilePic} variant="rounded">
              A
            </Avatar>
          </div>
          <IconButton
          //    onClick={handleClick}
          >
            <ExpandMoreIcon style={{ color: "#ffffff" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem>
            <ListItemButton onClick={() => handleLogout()}>
              <ListItemIcon sx={{ width: "30px" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover> */}
    </div>
  );
};
