import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export const getRepoid = async (uniqueid) => {
  try {
    let params = {
      db_name: `${sessionStorage.getItem("databasename")}`,
      entity: "IDM_Repository",
      filter: `IDM_Repository.unique_id=='${uniqueid}' && IDM_Repository.activestatus==true`,
      return_fields: "IDM_Repository",
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL_READ}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    const res = await axios(config);
    const data = await res.data.result;
    return data;
  } catch (error) {}
};

export const upsertRepo = async (repname, parent_rep, _id) => {
  try {
    let params = [
      {
        db_name: `${sessionStorage.getItem("databasename")}`,
        entity: "IDM_Repository",
        is_metadata: false,
        doc: {
          rep_id: uuidv4(),
          rep_type: "Report Configurator",
          rep_name: repname,
          parent_rep: parent_rep ?? "",
          route_url: "",
          is_active: true,
          unique_id: _id,
          projectid: `${sessionStorage.getItem("projectid")}`,
          clientid: `${sessionStorage.getItem("clientid")}`,
          metadataid: `${sessionStorage.getItem("metadataId")}`,
        },
      },
    ];
    await AxiosCall(process.env.REACT_APP_API_URL_UPSERT, params);
  } catch (error) {
    console.log(error);
  }
};

export const UpdateRepo = async (_key, repname, parent_rep, active) => {
  try {
    let params = [
      {
        db_name: `${sessionStorage.getItem("databasename")}`,
        entity: "IDM_Repository",
        filter: {
          _key: _key,
        },
        is_metadata: false,
        doc: {
          // rep_id: _id,
          // rep_type: reptype,
          rep_name: repname,
          parent_rep: parent_rep ?? "",
          ...(active && { is_active: true }),
          // route_url: "",
        },
      },
    ];
    await AxiosCall(process.env.REACT_APP_API_URL_UPSERT, params);
  } catch (error) {
    console.log(error);
  }
};

export const AxiosCall = async (url, params) => {
  let config = {
    method: "post",
    url: `${url}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  };
  let data = await axios(config);
  return data;
};

/* Get Arango MetaJSON schema */

export const getMetaSamepleJson = async (db_name, entity) => {
  let params = {
    db_name: db_name,
    entity: entity,
    metadata_dbname: `${process.env.REACT_APP_METADATA_DB_NAME}`,
  };
  let res = await AxiosCall(
    process.env.REACT_APP_API_GETMETASAMPLEJSON,
    params
  );
  let data = await res?.data[0]?.doc;
  return data;
  /* if (data?.Code === 1111) {
    let _ = {};
    return _;
  } else if (data) {
    let _ = data[0]?.doc;
    return _;
  } */
};

export const getDataMappingFilter = async (url, inputparam) => {
  let params = JSON.parse(inputparam);
  let res = await AxiosCall(url, params);
  let data = await res?.data;
  return data;
};
