import React from "react";

import Trashcan from "../images/Trashcav.png";

import Stack from "@mui/material/Stack";
import { Typography, Button } from "@mui/material";

function Delete(props) {
  // console.log(props)

  return (
    <div>
      <Stack direction="column" spacing={1} align="center">
        <img
          src={Trashcan}
          alt="Trashcan.png"
          style={{
            width: "60px",
            height: "75px",
            marginLeft: "100px",
            marginTop: "30px",
          }}
        />
        <Typography
          style={{
            fontSize: "29px",
            fontWeight: "500",
            width: "200px",
            marginLeft: "35px",
            marginBottom: "10px",
            marginTop: "20px",
          }}
        >
          Are you Sure ?
        </Typography>
        <Typography
          style={{
            fontSize: "12px",
            width: "256px",
            marginBottom: "7px",
            fontfamily: "Arial",
            marginLeft: "5px",
          }}
        >
          Do you really want to delete this Record?
        </Typography>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            onClick={props.handleClosefordeletepopup}
            style={{
              backgroundColor: "GrayText",
              margin: "2px 2px 15px 2px",
            }}
          >
            {" "}
            Cancel
          </Button>
          <Button
            onClick={props.deletefunc}
            variant="contained"
            style={{
              backgroundColor: "red",
              margin: "2px 2px 15px 2px",
            }}
          >
            {" "}
            Delete
          </Button>
        </span>
      </Stack>
    </div>
  );
}

export default Delete;
