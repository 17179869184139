import React from "react";
import {
  Button,
  Typography,
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import axios from "axios";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  root: {
    ".MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "0px",
    },
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Pdfviewer(props) {
  console.log(props);
  const [open, setOpen] = React.useState(true);
  const [pdf, setPdf] = React.useState("");
  const [finalList, setFinallist] = React.useState({});
  const [listParams, setListParams] = React.useState([]);
  const [pdfReportId, setPdfReportId] = React.useState("");
  const classes = useStyles();
  console.log(props.data);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {};
  React.useEffect(() => {
    setListParams(props.data.inputparams);
    setPdfReportId(props.data.reportPdfId);
  }, []);
  const changeHandle = (evt) => {
    console.log(evt.target.name, evt.target.value);
    Object.assign(finalList, { [evt.target.name]: evt.target.value });
    setFinallist(finalList);
  };
  const applyFunction = async () => {
    await axios({
      method: "post",
      url: process.env.REACT_APP_API_GENERATEREPORT,
      headers: {
        "content-type": "application/json",
      },
      data: {
        reportid: pdfReportId,
        inputparams: finalList,
        result: [],
      },
    })
      .then((res) => {
        console.log(res.data.downloadUrl);

        axios(res.data.downloadUrl, {
          method: "GET",
          responseType: "blob", //Force to receive data in a Blob Format
        })
          .then((response) => {
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            setPdf(fileURL);
            handleClose();
            //window.open(fileURL);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((err) => console.error(err));
  };
  return (
    <div>
      <Dialog
        fullWidth={"md"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Get Input Params"}</DialogTitle>
        <DialogContent>
          {console.log("listparams", listParams)}
          {listParams.map(
            (e) =>
              e.includes("@") && (
                <>
                  <Typography>{e}</Typography>
                  <TextField
                    className={classes.root}
                    name={e}
                    fullWidth={true}
                    onChange={changeHandle}
                  ></TextField>
                </>
              )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={applyFunction}>Apply</Button>
        </DialogActions>
      </Dialog>
      <embed src={pdf} width="100%" style={{ height: "70vh" }} />
    </div>
  );
}

export default Pdfviewer;
